<script>
import TravelExportService from "@/services/TravelExportService";
import dayjs from "dayjs";

export default {
    props: {
        exportedDate: {
            required: true
        }
    },
    data() {
        return {
            loading: true,
            travels: [],
            showRow: {}
        };
    },
    computed: {
        exportDateUrl() {
            const dt = dayjs(this.exportedDate);
            return `/v1/travel-exports/salary-report/${encodeURIComponent(
                dt.format("YYYY-MM-DD HH:mm:ss")
            )}/csv`;
        },
        exportDetailedDateUrl() {
            const dt = dayjs(this.exportedDate);
            return `/v1/travel-exports/salary-report/${encodeURIComponent(
                dt.format("YYYY-MM-DD HH:mm:ss")
            )}/csvd`;
        }
    },
    async created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                console.log("fetch data", this.exportedDate);
                this.loading = true;
                const dt = dayjs(this.exportedDate);
                const { data } = await TravelExportService.GET_salaryReport(
                    dt.format("YYYY-MM-DD HH:mm:ss")
                );
                this.travels = data.data;
                this.travels.forEach(t => {
                    this.$set(this.showRow, t.user_id, false);
                });
            } catch (e) {
                console.log("f e", e);
            }

            this.loading = false;
        }
    }
};
</script>

<template>
    <modal
        size="xxl"
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="asset-modal"
    >
        <template slot="header">
            {{ $t("salary.tp.header_salary_report") }} -
            {{ $d(new Date(exportedDate), "longFull") }}

            <a class="btn btn-primary btn-sm ml-3" :href="exportDateUrl"><i class="far fa-file-csv"></i> {{
                $t("salary.btn_export_to_CSV")
            }}</a>
            <a class="btn btn-primary btn-sm ml-3" :href="exportDetailedDateUrl"><i class="far fa-search"></i> {{
                $t("salary.btn_export_detailed_to_CSV")
            }}</a>
        </template>

        <template slot="default">
            <div class="">
                <v-table class="table table-sm table-mg">
                    <v-thead>
                        <v-tr>
                            <v-th>{{ $t("travels.hdr_name") }}</v-th>
                            <v-th>{{ $t("travels.hdr_employee_number") }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.hdr_km_low_rate")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.hdr_km_low_rate_amount")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.hdr_km_high_rate")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.hdr_km_high_rate_amount")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.hdr_total_km")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.hdr_total_amount")
                            }}</v-th>
                            <v-th> </v-th>
                        </v-tr>
                    </v-thead>
                    <v-tbody>
                        <template v-for="(t, idx) in travels">
                            <v-tr>
                                <v-td>{{ t.name }} </v-td>
                                <v-td>{{ t.employee_number }}</v-td>
                                <v-td class="text-right">{{
                                    $n(t.km_low)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(t.price_low, {
                                        style: "currency",
                                        currency: t.currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(t.km_high)
                                }}</v-td>
                                <v-td class="text-right">{{
                                    $n(t.price_high, {
                                        style: "currency",
                                        currency: t.currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                                <v-td class="text-right text-bold">{{
                                    $n(t.km_total)
                                }}</v-td>
                                <v-td class="text-right text-bold">{{
                                    $n(t.price_total, {
                                        style: "currency",
                                        currency: t.currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                                <v-td class="text-right">
                                    <i
                                        class="far fa-eye fa-lg m-click"
                                        @click="
                                            showRow[t.user_id] = !showRow[
                                                t.user_id
                                            ]
                                        "
                                    ></i>
                                </v-td>
                            </v-tr>
                            <v-tr v-if="showRow[t.user_id]">
                                <v-td colspan="9">
                                    <ul>
                                        <li
                                            v-for="tr in t.travels"
                                            :key="tr.id"
                                            class="pb-2"
                                        >
                                            {{
                                                $d(
                                                    new Date(tr.travel_date),
                                                    "short"
                                                )
                                            }}, {{ tr.kms }} km<template
                                                v-if="tr.project_id"
                                                >, {{ $t("travels.project") }}:
                                                #{{ tr.project_id }}
                                                {{ tr.project_title }}
                                            </template>
                                            <div class="text-italic">
                                                {{ tr.invoice_text }} /
                                                {{ tr.notes }}
                                            </div>
                                        </li>
                                    </ul>
                                </v-td>
                            </v-tr>
                        </template>
                    </v-tbody>
                </v-table>

                <v-table class="table-mg table table-sm" v-if="false">
                    <v-thead>
                        <v-tr>
                            <v-th class="w-15">{{
                                $t("travels.index.tbl_header_date")
                            }}</v-th>
                            <v-th class="w-40">{{
                                $t("travels.index.tbl_header_case")
                            }}</v-th>
                            <v-th class="w-25">{{
                                $t("travels.index.tbl_header_text")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.index.tbl_header_rate")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.index.tbl_header_kms")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.index.tbl_header_km_money")
                            }}</v-th>
                        </v-tr>
                    </v-thead>
                    <v-tbody>
                        <v-tr v-for="t in report.data" :key="t.id">
                            <v-td>{{
                                $d(new Date(t.travel_date), "short")
                            }}</v-td>
                            <v-td>
                                <template v-if="t.project_id">
                                    {{ t.project_title }}
                                    <div class="small text-muted">
                                        #{{ t.project_id }}
                                    </div>
                                </template>
                                <template v-else>---</template>
                            </v-td>
                            <v-td
                                >{{ t.invoice_text }} /
                                {{ t.notes }}
                            </v-td>
                            <v-td class="text-right">{{
                                $n(t.tax_rate_per_km, {
                                    style: "currency",
                                    currency: t.tax_rate_currency,
                                    currencyDisplay: "code"
                                })
                            }}</v-td>
                            <v-td class="text-right">{{ t.kms }} km</v-td>
                            <v-td class="text-right">{{
                                $n(t.tax_rate_per_km * t.kms, {
                                    style: "currency",
                                    currency: t.tax_rate_currency,
                                    currencyDisplay: "code"
                                })
                            }}</v-td>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </div>
        </template>
    </modal>
</template>

<style lang="scss">
.w-15 {
    width: 15% !important;
}
</style>
