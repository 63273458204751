<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import TravelType from "@/components/Travel/TravelType";
import VFilters from "@/components/Search/VFilters";
import VTravelForm from "@/components/Forms/VTravelForm";
import TravelService from "@/services/TravelService";
import ProjectService from "@/services/ProjectService";

export default {
    name: "VMyTravels",
    mixins: [DateRangeMixin],
    components: {
        VFilters,
        VTravelForm,
        DateRangePicker,
        TravelType,
    },
    data() {
        return {
            loading: true,
            travels: [],
            meta: {},
            projects: [],
            stats: false,
            travelModal: false,
            editContext: null,
            showNote: false,
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getAuthUser"]),
        isPrivateCar() {
            return [
                { label: this.$t("task.filter_private_car"), value: "1" },
                { label: this.$t("task.filter_company_car"), value: "0" },
            ];
        },
        isExported() {
            return [
                { label: this.$t("task.filter_exported"), value: "1" },
                { label: this.$t("task.filter_draft"), value: "0" },
            ];
        },
    },
    created() {
        this.resetSearch();
        this.$apiEnableWatcher();
        this.fetchData();
    },
    methods: {
        async fetchSearchData() {
            this.fetchProjects();
        },
        async fetchProjects() {
            try {
                const r = await ProjectService.GET_dropdown();
                this.projects = r.data.data;
            } catch (e) {
                console.log("f p", e);
            }
        },
        async resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            };
            this.apiPaginator = {
                direction: "desc",
                limit: null,
                page: null,
                sort: "travel_date",
            };
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["user_id"] = this.getMyId;
                params["includes"] = "project";
                const { data } = await TravelService.GET(params);
                this.travels = data.data;
                this.meta = data.meta;
                this.stats = data.meta.stats;
            } catch (e) {
                console.log("f e", e);
            }

            this.loading = false;
        },
        /**
         * Fetch the project (mainly for settings)
         */
        async fetchProject(loading = true) {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
            this.loading = loading;
        },
        /**
         * Set editing context
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.travelModal = true;
        },
        closeSave() {
            this.travelModal = false;
            this.fetchData();
        },
        /**
         * Show note
         */
        doShowNote(ctx) {
            this.showNote = true;
            this.showNoteContext = ctx;
        },
        /**
         * HIde note
         */
        doHideNote() {
            this.showNoteContext = null;
            this.showNote = false;
        },
    },
};
</script>

<template>
    <div class="v-my-travels">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div v-if="false" class="col-12">dash cards</div>

                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4">
                            <v-filters
                                :search="false"
                                :enable-filters="true"
                                @filters-open="fetchSearchData"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <template slot="right">
                                    <v-loader
                                        :loading="loading"
                                        v-if="loading"
                                    />
                                    <div
                                        class="project-stats"
                                        v-else-if="stats"
                                    >
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{ $t("travel.box_total_kms") }}
                                            </div>
                                            <div class="box-data">
                                                {{ stats.total_kms }} km
                                            </div>
                                        </div>
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{
                                                    $t(
                                                        "travel.box_total_kms_private"
                                                    )
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{ stats.total_kms_private }} km
                                            </div>
                                        </div>
                                        <div class="project-stats__box">
                                            <div class="box-title">
                                                {{
                                                    $t(
                                                        "travel.box_total_kms_company"
                                                    )
                                                }}
                                            </div>
                                            <div class="box-data">
                                                {{ stats.total_kms_company }} km
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- end of right slot -> stats -->
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-4">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                                <!-- daterange -->

                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.is_private_car"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_private_car'
                                            )
                                        "
                                        type="select"
                                        :options="isPrivateCar"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.is_exported"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_exported'
                                            )
                                        "
                                        type="select"
                                        :options="isExported"
                                    />
                                </div>
                                <div class="col-12 col-md-4">
                                    <FormulateInput
                                        v-model="apiSearchFilter.project_id"
                                        :placeholder="
                                            $t(
                                                'my_tasks.search_placeholder_project'
                                            )
                                        "
                                        type="select"
                                        :options="projects"
                                    />
                                </div>
                            </v-filters>
                        </div>

                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="travels.length > 0">
                            <v-table class="table-mg table table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="travel_date"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("travel.travel_date")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th>{{
                                            $t("travel.project_title")
                                        }}</v-th>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="invoice_text"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("travel.invoice_text")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="notes"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("travel.internal_notes")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class=""
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="is_private_car"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("travel.private_car")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-right"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name="kms"
                                                @sort="$handleSorting($event)"
                                                >{{ $t("travel.km") }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th>
                                            <v-sorter
                                                :paginator="apiPaginator"
                                                name="is_invoiced"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("travel.is_invoiced")
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th>
                                            <v-sorter
                                                :paginator="apiPaginator"
                                                name="is_exported"
                                                @sort="$handleSorting($event)"
                                                >{{
                                                    $t("travel.is_exported")
                                                }}</v-sorter
                                            >
                                        </v-th>
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="t in travels" :key="t.id">
                                        <v-td>{{
                                            $d(new Date(t.travel_date), "short")
                                        }}</v-td>
                                        <v-td>
                                            <router-link
                                                v-if="t.project_id"
                                                :to="{
                                                    name: 'ProjectViewTransport',
                                                    params: {
                                                        project_id:
                                                            t.project.id,
                                                    },
                                                }"
                                                >{{
                                                    t.project.title
                                                }}</router-link
                                            >
                                            <template v-else>---</template>
                                            <div class="small text-muted">
                                                <template v-if="t.project_id">
                                                    #{{ t.project.id }}
                                                </template>
                                            </div>
                                        </v-td>
                                        <v-td>
                                            {{ t.invoice_text }}
                                        </v-td>
                                        <v-td class="text-center">
                                            <i
                                                class="far fa-comment-alt-lines fa-lg m-click"
                                                @click="doShowNote(t.notes)"
                                                v-if="t.notes"
                                            ></i>
                                            <i
                                                class="far fa-comment-alt-slash text-muted fa-lg"
                                                v-else
                                            ></i>
                                        </v-td>
                                        <v-td>
                                            <travel-type
                                                :context="t.is_private_car"
                                            />
                                        </v-td>
                                        <v-td class="text-right"
                                            >{{ t.kms }} km</v-td
                                        >
                                        <v-td>
                                            <template v-if="t.project_id">
                                                {{ t.is_invoiced_name }}
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td>
                                            <template
                                                v-if="
                                                    parseInt(
                                                        t.is_private_car
                                                    ) === 1
                                                "
                                            >
                                                <template v-if="t.is_exported">
                                                    {{
                                                        $t(
                                                            "travel.exported_yes"
                                                        )
                                                    }}
                                                    <div
                                                        class="small text-muted"
                                                    >
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    t.exported_date
                                                                ),
                                                                "short"
                                                            )
                                                        }}
                                                    </div>
                                                </template>
                                                <template v-else>{{
                                                    $t("travel.exported_no")
                                                }}</template>
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td class="text-right">
                                            <i
                                                class="far fa-ban fa-lg"
                                                v-if="t.is_invoiced == 1"
                                            ></i>
                                            <i
                                                v-else
                                                class="far fa-pencil fa-lg m-click"
                                                @click="setContext(t)"
                                            ></i>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="px-4">
                                <div class="alert alert-warning">
                                    {{ $t("travels.no_data_found") }}
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <modal v-if="showNote" size="lg" :show="true" @close="doHideNote">
                <template slot="header">
                    {{ $t("travel.note_header") }}
                </template>
                <template slot="default">
                    {{ showNoteContext }}
                </template>
            </modal>
            <v-travel-form
                v-if="travelModal"
                :context="editContext"
                @close="travelModal = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>


<style>
</style>
