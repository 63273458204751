<script>
export default {
    props: {
        context: {},
    },
    computed: {
        isPrivate() {
            if (parseInt(this.context) === 1) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<template>
    <span :class="{ 'text-success': isPrivate}">
        <template v-if="isPrivate">{{ $t("travel.yes_private_car") }}</template>
        <template v-else>{{ $t("travel.no_company_car") }}</template>
    </span>
</template>


<style lang="scss">
</style>
