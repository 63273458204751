var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-travels-index"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"px-4 mb-4"},[_c('v-filters',{attrs:{"search":false,"start-open":true,"enable-filters":true},on:{"reset-search":_vm.resetSearch,"filter-view-change":function($event){return _vm.$handleFilterView($event)}}},[_c('div',{staticClass:"col-12 col-md-3"},[_c('FormulateInput',{attrs:{"label":_vm.$t('travels.department'),"type":"fselect","options":_vm.deps,"multiple":true,"placeholder":_vm.$t(
                                            'stats.placeholder_select_department'
                                        )},model:{value:(_vm.apiSearchFilter.department_id),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "department_id", $$v)},expression:"apiSearchFilter.department_id"}})],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('FormulateInput',{attrs:{"type":"date","label":_vm.$t(
                                            'travels.show_only_up_to_selected_date'
                                        )},model:{value:(_vm.apiSearchFilter.to_travel_date),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "to_travel_date", $$v)},expression:"apiSearchFilter.to_travel_date"}})],1)])],1),(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.travels.length > 0)?[_c('div',{staticClass:"px-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("salary.salary_export_lead_in"))+" ")]),_c('v-table',{staticClass:"table-mg table table-sm table-striped"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectAll),expression:"selectAll"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.selectAll)?_vm._i(_vm.selectAll,null)>-1:(_vm.selectAll)},on:{"change":function($event){var $$a=_vm.selectAll,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectAll=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectAll=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectAll=$$c}}}})]),_c('v-th',[_vm._v(_vm._s(_vm.$t("salary.report.tbl_user")))]),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("salary.report.tbl_empl_no")))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "salary.report.tbl_private_travel_exported" )))]),_c('v-th',[_vm._v(" "+_vm._s(_vm.$t( "salary.report.tbl_private_history" ))+" ")]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "salary.report.tbl_awaiting_export" )))])],1)],1),_c('v-tbody',_vm._l((_vm.travels),function(t,index){return _c('v-tr',{key:t.user_id},[_c('v-td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedTravels),expression:"selectedTravels"}],attrs:{"type":"checkbox","disabled":parseInt(
                                                    t.total_kms_private_draft
                                                ) === 0},domProps:{"value":index,"checked":Array.isArray(_vm.selectedTravels)?_vm._i(_vm.selectedTravels,index)>-1:(_vm.selectedTravels)},on:{"change":function($event){var $$a=_vm.selectedTravels,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=index,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedTravels=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedTravels=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedTravels=$$c}}}})]),_c('v-td',{class:{
                                            'text-danger text-bold':
                                                parseInt(
                                                    t.total_kms_private_draft
                                                ) > 0
                                        }},[_vm._v(_vm._s(t.user.name))]),_c('v-td',{staticClass:"text-center"},[(t.user.employee_number)?[_vm._v(_vm._s(t.user.employee_number))]:[_vm._v("---")]],2),_c('v-td',{staticClass:"text-right"},[(
                                                parseInt(
                                                    t.total_kms_private_exported
                                                ) > 0
                                            )?[_c('span',{staticClass:"m-click",on:{"click":function($event){return _vm.showModal(
                                                        t.user_id,
                                                        '1'
                                                    )}}},[_c('i',{staticClass:"fa fa-search"}),_vm._v(" "+_vm._s(t.total_kms_private_exported)+" km")])]:[_vm._v("0 km")]],2),_c('v-td',[_c('ul',_vm._l((t.details),function(idx,a){return _c('li',{key:a},[_vm._v(" "+_vm._s(_vm.$d( new Date( idx.tp_range.date_from ), "short" ))+" - "+_vm._s(_vm.$d( new Date( idx.tp_range.date_to ), "short" ))+": "+_vm._s(_vm.$n( idx.total_kms_private ))+" km "),(
                                                        idx.total_kms_private_draft >
                                                            0
                                                    )?_c('span',{staticClass:"text-danger"},[_vm._v("("+_vm._s(idx.total_kms_private_draft)+" km)")]):_vm._e()])}),0)]),_c('v-td',{staticClass:"text-right",class:{
                                            'text-danger text-bold':
                                                parseInt(
                                                    t.total_kms_private_draft
                                                ) > 0
                                        }},[(
                                                parseInt(
                                                    t.total_kms_private_draft
                                                ) > 0
                                            )?[_c('span',{staticClass:"m-click",on:{"click":function($event){return _vm.showModal(
                                                        t.user_id,
                                                        '0'
                                                    )}}},[_c('i',{staticClass:"fa fa-search"}),_vm._v(" "+_vm._s(t.total_kms_private_draft)+" km ")]),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$n( t.total_kms_private_draft_price, { style: "currency", currency: t.currency, currencyDisplay: "code" } ))+" ")])]:[_c('i',{staticClass:"fa fa-check text-success"})]],2)],1)}),1)],1),_c('div',{staticClass:"text-right mt-4 px-4"},[_c('hr'),_c('base-button',{attrs:{"disabled":_vm.selectedTravels.length === 0,"type":"primary","loading":_vm.saving},on:{"click":_vm.exportToSalary}},[_vm._v(_vm._s(_vm.$t("salary.export_to_csv_and_mark")))])],1)]:[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("travels.no_data_found"))+" ")])])]],2)],1)])]),_c('portal',{attrs:{"to":"modals"}},[(_vm.showTravelModal)?_c('TravelSourceModal',{attrs:{"user-id":_vm.showTravelContext,"is-exported":_vm.showTravelMode,"to-date":_vm.apiSearchFilter.to_travel_date},on:{"close":_vm.hideModal}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }