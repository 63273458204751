<script>
import TravelSourceModal from "@/views/Travels/TravelSourceModal";
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import VFilters from "@/components/Search/VFilters";
import TravelType from "@/components/Travel/TravelType";
import VTravelForm from "@/components/Forms/VTravelForm";
import TravelService from "@/services/TravelService";
import TravelExportService from "@/services/TravelExportService";
import ProjectService from "@/services/ProjectService";
import UserService from "@/services/UserService";
import MessagesMixin from "@/mixins/Messages";
import DepartmentService from "@/services/DepartmentService";

export default {
    name: "VSalary",
    mixins: [DateRangeMixin, MessagesMixin],
    components: {
        VFilters,
        TravelSourceModal
    },
    data() {
        return {
            loading: true,
            saving: false,
            travels: [],
            projects: [],
            users: [],
            travelModal: false,
            editContext: null,
            showNote: false,
            selectedTravels: [],
            showTravelModal: false,
            showTravelContext: "",
            showTravelMode: "",
            deps: []
        };
    },
    computed: {
        selectAll: {
            get: function() {
                return this.travels
                    ? this.selectedTravels.length == this.travels.length
                    : false;
            },

            set: function(v) {
                let selected = [];

                if (v) {
                    this.travels.forEach(function(v, index) {
                        if (parseInt(v.total_kms_private_draft) > 0) {
                            selected.push(index);
                        }
                    });
                }

                this.selectedTravels = selected;
            }
        }
    },
    created() {
        this.resetSearch();
        this.$apiEnableWatcher();
        this.fetchDepartments(true);
        this.fetchData();
    },

    methods: {
        async fetchDepartments(loading = false) {
            try {
                this.loading = true;
                const r = await DepartmentService.dropdown();
                this.deps = r.data.data;
            } catch (e) {
                console.log("fetchDepartments e", e);
            }

            this.loading = loading;
        },
        showModal(user_id, mode = "0") {
            this.showTravelContext = user_id;
            this.showTravelModal = true;
            this.showTravelMode = mode;
        },
        hideModal() {
            this.showTravelModal = false;
            this.showTravelContext = "";
            this.showTravelMode = "";
        },
        /**
         * Security modal
         */
        async exportToSalary() {
            this.$swal({
                icon: "warning",
                text: this.$t(
                    "salary.swal_you_are_about_to_export_the_salary_km_WARNING"
                ),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.doExportToSalary();
                }
            });
        },
        /**
         * Export salary report and mark as exported
         */
        async doExportToSalary() {
            try {
                this.saving = true;
                let copy = [];

                this.selectedTravels.forEach(key => {
                    copy.push(this.travels[key]);
                });

                const { data } = await TravelService.PUT_exportToSalary({
                    entries: copy,
                    to_travel_date: this.apiSearchFilter.to_travel_date
                });
                window.location = `/v1/travels/download-salary-report/${data.data.ident}`;

                await this.fetchData();
                this.$msgSuccess(this.$t("salary.notify_export_success"));
            } catch (e) {
                console.log("exportToSalary e", e);
                this.$msgError(
                    this.$t("salary.notify_error_export_not_changed")
                );
            }

            this.saving = false;
        },
        async fetchUsers() {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers exception", e);
            }
            this.loading = false;
        },
        /**
         * Fetch salary data
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                this.selectedTravels = [];
                const { data } = await TravelService.GET_salary(params);
                this.travels = data.data;
            } catch (e) {
                console.log("f e", e);
            }

            this.loading = false;
        },
        async fetchSearchData() {
            this.fetchUsers();
        },
        async fetchProjects() {
            try {
                const r = await ProjectService.GET_dropdown();
                this.projects = r.data.data;
            } catch (e) {
                console.log("f p", e);
            }
        },
        async resetSearch() {
            this.apiSearchFilter = {
                to_travel_date: "",
                department_id: ""
            };
        }
    }
};
</script>
<template>
    <div class="v-travels-index">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4 mb-4">
                            <v-filters
                                :search="false"
                                :start-open="true"
                                :enable-filters="true"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-3">
                                    <FormulateInput
                                        :label="$t('travels.department')"
                                        type="fselect"
                                        :options="deps"
                                        :multiple="true"
                                        v-model="apiSearchFilter.department_id"
                                        :placeholder="
                                            $t(
                                                'stats.placeholder_select_department'
                                            )
                                        "
                                    />
                                </div>
                                <div class="col-12 col-md-3">
                                    <FormulateInput
                                        type="date"
                                        :label="
                                            $t(
                                                'travels.show_only_up_to_selected_date'
                                            )
                                        "
                                        v-model="apiSearchFilter.to_travel_date"
                                    />
                                </div>
                            </v-filters>
                        </div>

                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="travels.length > 0">
                            <div class="px-4 mb-4">
                                {{ $t("salary.salary_export_lead_in") }}
                            </div>
                            <v-table
                                class="table-mg table table-sm table-striped"
                            >
                                <v-thead>
                                    <v-tr>
                                        <v-th>
                                            <input
                                                type="checkbox"
                                                v-model="selectAll"
                                            />
                                        </v-th>
                                        <v-th>{{
                                            $t("salary.report.tbl_user")
                                        }}</v-th>
                                        <v-th class="text-center">{{
                                            $t("salary.report.tbl_empl_no")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "salary.report.tbl_private_travel_exported"
                                            )
                                        }}</v-th>
                                        <v-th>
                                            {{
                                                $t(
                                                    "salary.report.tbl_private_history"
                                                )
                                            }}
                                        </v-th>

                                        <v-th class="text-right">{{
                                            $t(
                                                "salary.report.tbl_awaiting_export"
                                            )
                                        }}</v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="(t, index) in travels"
                                        :key="t.user_id"
                                    >
                                        <v-td>
                                            <input
                                                type="checkbox"
                                                :value="index"
                                                v-model="selectedTravels"
                                                :disabled="
                                                    parseInt(
                                                        t.total_kms_private_draft
                                                    ) === 0
                                                "
                                            />
                                        </v-td>
                                        <v-td
                                            :class="{
                                                'text-danger text-bold':
                                                    parseInt(
                                                        t.total_kms_private_draft
                                                    ) > 0
                                            }"
                                            >{{ t.user.name }}</v-td
                                        >
                                        <v-td class="text-center">
                                            <template
                                                v-if="t.user.employee_number"
                                                >{{
                                                    t.user.employee_number
                                                }}</template
                                            ><template v-else
                                                >---</template
                                            ></v-td
                                        >
                                        <v-td class="text-right">
                                            <template
                                                v-if="
                                                    parseInt(
                                                        t.total_kms_private_exported
                                                    ) > 0
                                                "
                                            >
                                                <span
                                                    class="m-click"
                                                    @click="
                                                        showModal(
                                                            t.user_id,
                                                            '1'
                                                        )
                                                    "
                                                    ><i
                                                        class="fa fa-search"
                                                    ></i>
                                                    {{
                                                        t.total_kms_private_exported
                                                    }}
                                                    km</span
                                                >                                                
                                            </template>
                                            <template v-else>0 km</template>
                                        </v-td>
                                        <v-td>
                                            <ul>
                                                <li
                                                    v-for="(idx,
                                                    a) in t.details"
                                                    :key="a"
                                                >
                                                    {{
                                                        $d(
                                                            new Date(
                                                                idx.tp_range.date_from
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                    -
                                                    {{
                                                        $d(
                                                            new Date(
                                                                idx.tp_range.date_to
                                                            ),
                                                            "short"
                                                        )
                                                    }}:

                                                    {{
                                                        $n(
                                                            idx.total_kms_private
                                                        )
                                                    }}
                                                    km
                                                    <span
                                                        class="text-danger"
                                                        v-if="
                                                            idx.total_kms_private_draft >
                                                                0
                                                        "
                                                        >({{
                                                            idx.total_kms_private_draft
                                                        }}
                                                        km)</span
                                                    >
                                                </li>
                                            </ul>
                                        </v-td>
                                        <v-td
                                            class="text-right"
                                            :class="{
                                                'text-danger text-bold':
                                                    parseInt(
                                                        t.total_kms_private_draft
                                                    ) > 0
                                            }"
                                            ><template
                                                v-if="
                                                    parseInt(
                                                        t.total_kms_private_draft
                                                    ) > 0
                                                "
                                            >
                                                <span
                                                    class="m-click"
                                                    @click="
                                                        showModal(
                                                            t.user_id,
                                                            '0'
                                                        )
                                                    "
                                                    ><i
                                                        class="fa fa-search"
                                                    ></i>
                                                    {{
                                                        t.total_kms_private_draft
                                                    }}
                                                    km
                                                </span>
                                                <div class="small">
                                                    {{
                                                        $n(
                                                            t.total_kms_private_draft_price,
                                                            {
                                                                style:
                                                                    "currency",
                                                                currency:
                                                                    t.currency,
                                                                currencyDisplay:
                                                                    "code"
                                                            }
                                                        )
                                                    }}
                                                </div>
                                            </template>
                                            <template v-else
                                                ><i
                                                    class="fa fa-check text-success"
                                                ></i></template
                                        ></v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>

                            <div class="text-right mt-4 px-4">
                                <hr />
                                <base-button
                                    :disabled="selectedTravels.length === 0"
                                    type="primary"
                                    :loading="saving"
                                    @click="exportToSalary"
                                    >{{
                                        $t("salary.export_to_csv_and_mark")
                                    }}</base-button
                                >
                            </div>
                        </template>
                        <template v-else>
                            <div class="px-4">
                                <div class="alert alert-warning">
                                    {{ $t("travels.no_data_found") }}
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <TravelSourceModal
                :user-id="showTravelContext"
                :is-exported="showTravelMode"
                :to-date="apiSearchFilter.to_travel_date"
                v-if="showTravelModal"
                @close="hideModal"
            />
        </portal>
    </div>
</template>

<style lang="scss"></style>
