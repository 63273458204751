var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-travels-index"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[(false)?_c('div',{staticClass:"col-12"},[_vm._v("dash cards")]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"px-4"},[_c('v-filters',{attrs:{"search":false,"start-open":true,"enable-filters":true},on:{"reset-search":_vm.resetSearch,"filter-view-change":function($event){return _vm.$handleFilterView($event)}}},[_c('template',{slot:"left"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":_vm.currentExportUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("projects.download_as_csv")))])]),_c('template',{slot:"default"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('date-range-picker',{ref:"picker",staticClass:"range-picker",attrs:{"opens":"right","timePicker":false,"showWeekNumbers":true,"autoApply":true,"linkedCalendars":true,"locale-data":_vm.getDateRangePickerLocale(),"ranges":_vm.getDateRangePickerRanges()},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('manual-range',{attrs:{"date-range-manual":_vm.dateRangeManual,"date-range-error":_vm.dateRangeError},on:{"exec":function($event){return _vm.execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )}}})]},proxy:true},{key:"input",fn:function(picker){return [(picker.startDate)?[_vm._v(" "+_vm._s(_vm.$d( picker.startDate, "short" ))+" - "+_vm._s(_vm.$d( picker.endDate, "short" ))+" ")]:_vm._e()]}}]),model:{value:(_vm.apiSearchFilter.dateRange),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "dateRange", $$v)},expression:"apiSearchFilter.dateRange"}})],1),(true)?_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                                'my_tasks.search_placeholder_user'
                                            ),"type":"select","options":_vm.users},model:{value:(_vm.apiSearchFilter.user_id),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "user_id", $$v)},expression:"apiSearchFilter.user_id"}})],1):_vm._e(),(true)?_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"type":"fselect","options":_vm.deps,"multiple":true,"placeholder":_vm.$t(
                                                'stats.placeholder_select_department'
                                            )},model:{value:(
                                            _vm.apiSearchFilter.department_id
                                        ),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "department_id", $$v)},expression:"\n                                            apiSearchFilter.department_id\n                                        "}})],1):_vm._e(),_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"disabled":_vm.projects.length == 0,"placeholder":_vm.$t(
                                                'my_tasks.search_placeholder_case'
                                            ),"type":"select","options":_vm.projects},model:{value:(_vm.apiSearchFilter.project_id),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "project_id", $$v)},expression:"apiSearchFilter.project_id"}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                                'my_tasks.search_placeholder_private_car'
                                            ),"type":"select","options":_vm.isPrivateCar},model:{value:(
                                            _vm.apiSearchFilter.is_private_car
                                        ),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "is_private_car", $$v)},expression:"\n                                            apiSearchFilter.is_private_car\n                                        "}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t(
                                                'my_tasks.search_placeholder_exported'
                                            ),"type":"select","options":_vm.isExported},model:{value:(
                                            _vm.apiSearchFilter.is_exported
                                        ),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "is_exported", $$v)},expression:"\n                                            apiSearchFilter.is_exported\n                                        "}})],1)])],2)],1),(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.travels.length > 0)?[_c('v-table',{staticClass:"table-mg table table-sm"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("travels.index.tbl_header_date")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("travels.index.tbl_header_user")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("travels.index.tbl_header_case")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "travels.index.tbl_header_travel_type" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("travels.index.tbl_header_text")))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t("travels.index.tbl_header_kms")))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "travels.index.tbl_header_salary" )))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "travels.index.tbl_header_billable" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "travels.index.tbl_header_exported" )))])],1)],1),_c('v-tbody',_vm._l((_vm.travels),function(t){return _c('v-tr',{key:t.id},[_c('v-td',[_vm._v(" "+_vm._s(_vm.$d( new Date(t.travel_date), "short" ))+" ")]),_c('v-td',[_vm._v(_vm._s(t.user.name))]),_c('v-td',[(t.project_id)?_c('router-link',{attrs:{"to":{
                                                name:
                                                    'ProjectViewTransport',
                                                params: {
                                                    project_id: t.project.id
                                                }
                                            }}},[_vm._v(_vm._s(t.project.title))]):_vm._e(),_c('div',{staticClass:"small text-muted"},[(t.project_id)?[_vm._v("#"+_vm._s(t.project.id))]:[_vm._v("---")]],2)],1),_c('v-td',[_c('travel-type',{attrs:{"context":t.is_private_car}})],1),_c('v-td',[_vm._v(_vm._s(t.invoice_text)+" / "+_vm._s(t.notes)+" ")]),_c('v-td',{staticClass:"text-right"},[_vm._v(_vm._s(t.kms)+" km")]),_c('v-td',{staticClass:"text-right"},[(
                                                parseInt(
                                                    t.is_private_car
                                                ) === 1
                                            )?[_vm._v(" "+_vm._s(_vm.$n( t.kms * t.tax_rate_per_km, { style: "currency", currency: t.tax_rate_currency, currencyDisplay: "code" } ))+" "),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$n(t.tax_rate_per_km, { style: "currency", currency: t.tax_rate_currency, currencyDisplay: "code" }))+" ")])]:[_vm._v("---")]],2),_c('v-td',{staticClass:"text-right"},[(!t.project_id)?[_vm._v("---")]:(
                                                parseInt(t.is_billable) ===
                                                    1
                                            )?[(t.currency)?[_vm._v(" "+_vm._s(_vm.$n(t.km_price * t.kms, { style: "currency", currency: t.currency, currencyDisplay: "code" }))+" "),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$n(t.km_price, { style: "currency", currency: t.currency, currencyDisplay: "code" }))+" ")])]:_c('span',{staticClass:"text-warning"},[_vm._v(_vm._s(_vm.$t( "travels.km_price_missing" )))])]:[_vm._v(" --- ")]],2),_c('v-td',{staticClass:"text-center"},[(
                                                parseInt(t.is_exported) ===
                                                    1
                                            )?[_c('i',{staticClass:"far fa-check"})]:[_c('i',{staticClass:"far fa-times"})]],2)],1)}),1)],1),_c('div',{staticClass:"paginator"},[_c('base-pagination',{attrs:{"page-count":_vm.meta.pagination.total_pages,"per-page":_vm.meta.pagination.per_page,"value":_vm.meta.pagination.current_page},on:{"input":function($event){return _vm.$handlePagination($event)}}})],1)]:[_c('div',{staticClass:"px-4"},[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("travels.no_data_found"))+" ")])])]],2)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }