<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import VFilters from "@/components/Search/VFilters";
import TravelType from "@/components/Travel/TravelType";
import VTravelForm from "@/components/Forms/VTravelForm";
import TravelService from "@/services/TravelService";
import ProjectService from "@/services/ProjectService";
import UserService from "@/services/UserService";
import DepartmentService from "@/services/DepartmentService";

export default {
    name: "VIndex",
    mixins: [DateRangeMixin],
    components: {
        VFilters,
        DateRangePicker,
        TravelType
    },

    data() {
        return {
            loading: true,
            travels: [],
            meta: {},
            projects: [],
            stats: false,
            travelModal: false,
            editContext: null,
            showNote: false,
            users: [],
            deps: []
        };
    },
    computed: {
        isPrivateCar() {
            return [
                { label: this.$t("task.filter_private_car"), value: "1" },
                { label: this.$t("task.filter_company_car"), value: "0" }
            ];
        },
        isExported() {
            return [
                { label: this.$t("task.filter_exported"), value: "1" },
                { label: this.$t("task.filter_draft"), value: "0" }
            ];
        },
        currentExportUrl: function() {
            let params = this.$buildParams();
            delete params.sort;
            delete params.direction;
            delete params.page;

            let url = new URL(
                location.protocol + "//" + location.host + "/v1/travels"
            );
            url.searchParams.append("csv", 1);
            for (let param in params) {
                if (param == "dateRange") {
                    url.searchParams.append(
                        param,
                        JSON.stringify(params[param])
                    );
                } else {
                    if (Array.isArray(params[param])) {
                        params[param].forEach(function(value) {
                            url.searchParams.append(param + "[]", value);
                        });
                    } else {
                        url.searchParams.append(param, params[param]);
                    }
                }
            }

            return url.href;
        }
    },
    created() {
        this.resetSearch();
        this.fetchSearchData(true);
        this.$apiEnableWatcher();
        this.fetchData();
    },
    methods: {
        async fetchDepartments() {
            try {
                const r = await DepartmentService.dropdown();
                this.deps = r.data.data;
            } catch (e) {
                console.log("fetchDepartments e", e);
            }
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["includes"] = "project";
                params["sort"] = "travel_date";
                params["direction"] = "desc";
                const { data } = await TravelService.GET(params);
                this.travels = data.data;
                this.meta = data.meta;
                this.stats = data.meta.stats;
            } catch (e) {
                console.log("f e", e);
            }

            this.loading = false;
        },
        async fetchUsers(loading) {
            try {
                this.loading = true;
                const r = await UserService.dropdown();
                this.users = r.data.data;
            } catch (e) {
                console.log("fetchUsers exception", e);
            }
            this.loading = loading;
        },
        async fetchSearchData() {
            await this.fetchUsers();
            await this.fetchProjects();
            await this.fetchDepartments();
        },
        async fetchProjects() {
            try {
                const r = await ProjectService.GET_dropdown();
                this.projects = r.data.data;
            } catch (e) {
                console.log("f p", e);
            }
        },
        async resetSearch() {
            this.apiSearchFilter = {
                is_exported: "",
                project_id: "",
                user_id: "",
                dateRange: {
                    startDate: null,
                    endDate: null
                }
            };
        }
    }
};
</script>
<template>
    <div class="v-travels-index">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div v-if="false" class="col-12">dash cards</div>

                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4">
                            <v-filters
                                :search="false"
                                :start-open="true"
                                :enable-filters="true"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <template slot="left">
                                    <a
                                        :href="currentExportUrl"
                                        class="btn btn-primary"
                                        target="_blank"
                                        >{{ $t("projects.download_as_csv") }}</a
                                    >
                                </template>
                                <template slot="default">
                                    <!-- Default slot starts here -> filters -->
                                    <div class="col-12 col-md-4">
                                        <date-range-picker
                                            class="range-picker"
                                            ref="picker"
                                            opens="right"
                                            :timePicker="false"
                                            :showWeekNumbers="true"
                                            :autoApply="true"
                                            v-model="apiSearchFilter.dateRange"
                                            :linkedCalendars="true"
                                            :locale-data="
                                                getDateRangePickerLocale()
                                            "
                                            :ranges="getDateRangePickerRanges()"
                                        >
                                            <template #header>
                                                <manual-range
                                                    :date-range-manual="
                                                        dateRangeManual
                                                    "
                                                    :date-range-error="
                                                        dateRangeError
                                                    "
                                                    @exec="
                                                        execManualRange(
                                                            'apiSearchFilter',
                                                            'dateRange'
                                                        )
                                                    "
                                                />
                                            </template>
                                            <template
                                                v-slot:input="picker"
                                                style="min-width: 350px"
                                            >
                                                <template
                                                    v-if="picker.startDate"
                                                >
                                                    {{
                                                        $d(
                                                            picker.startDate,
                                                            "short"
                                                        )
                                                    }}
                                                    -
                                                    {{
                                                        $d(
                                                            picker.endDate,
                                                            "short"
                                                        )
                                                    }}
                                                </template>
                                            </template>
                                        </date-range-picker>
                                    </div>
                                    <!-- daterange -->

                                    <div class="col-12 col-md-4" v-if="true">
                                        <FormulateInput
                                            v-model="apiSearchFilter.user_id"
                                            :placeholder="
                                                $t(
                                                    'my_tasks.search_placeholder_user'
                                                )
                                            "
                                            type="select"
                                            :options="users"
                                        />
                                    </div>
                                    <div class="col-12 col-md-4" v-if="true">
                                        <FormulateInput
                                            type="fselect"
                                            :options="deps"
                                            :multiple="true"
                                            v-model="
                                                apiSearchFilter.department_id
                                            "
                                            :placeholder="
                                                $t(
                                                    'stats.placeholder_select_department'
                                                )
                                            "
                                        />
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <FormulateInput
                                            :disabled="projects.length == 0"
                                            v-model="apiSearchFilter.project_id"
                                            :placeholder="
                                                $t(
                                                    'my_tasks.search_placeholder_case'
                                                )
                                            "
                                            type="select"
                                            :options="projects"
                                        />
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <FormulateInput
                                            v-model="
                                                apiSearchFilter.is_private_car
                                            "
                                            :placeholder="
                                                $t(
                                                    'my_tasks.search_placeholder_private_car'
                                                )
                                            "
                                            type="select"
                                            :options="isPrivateCar"
                                        />
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <FormulateInput
                                            v-model="
                                                apiSearchFilter.is_exported
                                            "
                                            :placeholder="
                                                $t(
                                                    'my_tasks.search_placeholder_exported'
                                                )
                                            "
                                            type="select"
                                            :options="isExported"
                                        />
                                    </div>
                                </template>
                            </v-filters>
                        </div>

                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="travels.length > 0">
                            <v-table class="table-mg table table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("travels.index.tbl_header_date")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("travels.index.tbl_header_user")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("travels.index.tbl_header_case")
                                        }}</v-th>
                                        <v-th>{{
                                            $t(
                                                "travels.index.tbl_header_travel_type"
                                            )
                                        }}</v-th>

                                        <v-th>{{
                                            $t("travels.index.tbl_header_text")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("travels.index.tbl_header_kms")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "travels.index.tbl_header_salary"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "travels.index.tbl_header_billable"
                                            )
                                        }}</v-th>
                                        <v-th>{{
                                            $t(
                                                "travels.index.tbl_header_exported"
                                            )
                                        }}</v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="t in travels" :key="t.id">
                                        <v-td>
                                            {{
                                                $d(
                                                    new Date(t.travel_date),
                                                    "short"
                                                )
                                            }}
                                        </v-td>

                                        <v-td>{{ t.user.name }}</v-td>
                                        <v-td
                                            ><router-link
                                                v-if="t.project_id"
                                                :to="{
                                                    name:
                                                        'ProjectViewTransport',
                                                    params: {
                                                        project_id: t.project.id
                                                    }
                                                }"
                                                >{{
                                                    t.project.title
                                                }}</router-link
                                            >
                                            <div class="small text-muted">
                                                <template v-if="t.project_id"
                                                    >#{{
                                                        t.project.id
                                                    }}</template
                                                >
                                                <template v-else>---</template>
                                            </div>
                                        </v-td>
                                        <v-td
                                            ><travel-type
                                                :context="t.is_private_car"
                                        /></v-td>
                                        <v-td
                                            >{{ t.invoice_text }} /
                                            {{ t.notes }}
                                        </v-td>
                                        <v-td class="text-right"
                                            >{{ t.kms }} km</v-td
                                        >
                                        <v-td class="text-right">
                                            <template
                                                v-if="
                                                    parseInt(
                                                        t.is_private_car
                                                    ) === 1
                                                "
                                            >
                                                {{
                                                    $n(
                                                        t.kms *
                                                            t.tax_rate_per_km,
                                                        {
                                                            style: "currency",
                                                            currency:
                                                                t.tax_rate_currency,
                                                            currencyDisplay:
                                                                "code"
                                                        }
                                                    )
                                                }}
                                                <div class="small text-muted">
                                                    {{
                                                        $n(t.tax_rate_per_km, {
                                                            style: "currency",
                                                            currency:
                                                                t.tax_rate_currency,
                                                            currencyDisplay:
                                                                "code"
                                                        })
                                                    }}
                                                </div>
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td class="text-right">
                                            <template v-if="!t.project_id"
                                                >---</template
                                            >
                                            <template
                                                v-else-if="
                                                    parseInt(t.is_billable) ===
                                                        1
                                                "
                                            >
                                                <template v-if="t.currency">
                                                    {{
                                                        $n(t.km_price * t.kms, {
                                                            style: "currency",
                                                            currency:
                                                                t.currency,
                                                            currencyDisplay:
                                                                "code"
                                                        })
                                                    }}
                                                    <div
                                                        class="small text-muted"
                                                    >
                                                        {{
                                                            $n(t.km_price, {
                                                                style:
                                                                    "currency",
                                                                currency:
                                                                    t.currency,
                                                                currencyDisplay:
                                                                    "code"
                                                            })
                                                        }}
                                                    </div>
                                                </template>
                                                <span
                                                    class="text-warning"
                                                    v-else
                                                    >{{
                                                        $t(
                                                            "travels.km_price_missing"
                                                        )
                                                    }}</span
                                                >
                                            </template>
                                            <template v-else> --- </template>
                                        </v-td>
                                        <v-td class="text-center">
                                            <template
                                                v-if="
                                                    parseInt(t.is_exported) ===
                                                        1
                                                "
                                            >
                                                <i class="far fa-check"></i>
                                            </template>
                                            <template v-else>
                                                <i class="far fa-times"></i>
                                            </template>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="px-4">
                                <div class="alert alert-warning">
                                    {{ $t("travels.no_data_found") }}
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss"></style>
