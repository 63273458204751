<script>
import TravelPriceService from "@/services/TravelPriceService";
import VTravelPricesForm from "@/components/Forms/VTravelPricesForm";

export default {
    components: {
        VTravelPricesForm,
    },
    data() {
        return {
            loading: true,
            prices: [],
            formContext: { empty: true },
            showForm: false,
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.apiPaginator.sort = "date_to";
        this.apiPaginator.direction = "desc";
        this.$apiEnableWatcher();
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await TravelPriceService.GET(params);
                this.prices = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },
        setContext(ctx) {
            this.formContext = ctx;
            this.showForm = true;
        },
        closeRefresh() {
            this.showForm = false;
            this.fetchData();
        },
    },
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button
                    type="secondary"
                    size="sm"
                    @click="setContext({ empty: true })"
                    >{{ $t("travel_prices.add_new") }}</base-button
                >
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <div class="px-4 mt-2 mb-4">
                            {{ $t("travel_prices.lead_in_text") }}
                        </div>
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="prices.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th class="text-center"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="date_to"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("travel_prices.period")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right">{{
                                                $t("travel_prices.tax_price")
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "travel_prices.commercial_price"
                                                )
                                            }}</v-th>
                                            <v-th v-if="ACL_RW"></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr v-for="tp in prices" :key="tp.id">
                                            <v-td class="text-center">
                                                {{$d(new Date(tp.date_from), 'short')}} -
                                                {{$d(new Date(tp.date_to), 'short')}}

                                            </v-td>
                                            <v-td class="text-right">{{
                                                $n(tp.price_tax, {
                                                    style: "currency",
                                                    currency: "DKK",
                                                    currencyDisplay: "code",
                                                })
                                            }}</v-td>
                                            <v-td class="text-right">{{
                                                $n(tp.price_commercial, {
                                                    style: "currency",
                                                    currency: "DKK",
                                                    currencyDisplay: "code",
                                                })
                                            }}</v-td>
                                            <v-td
                                                class="text-right"
                                                v-if="ACL_RW"
                                            >
                                                <i
                                                    class="fa fa-lg fa-pencil fa-fw m-click"
                                                    @click="setContext(tp)"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>

        <portal to="modals">
            <v-travel-prices-form
                v-if="showForm"
                :context="formContext"
                @close="showForm = false"
                @save="closeRefresh"
            />
        </portal>
    </div>
</template>


<style>
</style>
