<script>
import TravelService from "@/services/TravelService";

export default {
    props: {
        userId: {},
        isExported: {
            type: String,
            default: "0",
        },
        toDate: {},
    },
    data() {
        return {
            loading: true,
            travels: [],
            meta: {},
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                params["includes"] = "project";
                params["user_id"] = this.userId;
                params["is_private_car"] = "1";
                params["is_exported"] = String(this.isExported);
                if (this.toDate) {
                    params["to_travel_date"] = this.toDate;
                }
                const { data } = await TravelService.GET(params);
                this.travels = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("f e", e);
            }

            this.loading = false;
        },
    },
};
</script>

<template>
    <modal
        size="xxl"
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="asset-modal"
    >
        <template slot="header">
            {{ $t("salary.smodal.header_private_travel_source") }} -
            <template v-if="parseInt(isExported) === 0">{{
                $t("salary.smodal_not_exported")
            }}</template>
            <template v-else>{{ $t("salary.smodal_exported") }}</template>
        </template>

        <template slot="default">
            <v-table class="table-mg table table-sm">
                <v-thead>
                    <v-tr>
                        <v-th>{{ $t("travels.index.tbl_header_date") }}</v-th>
                        <v-th>{{ $t("travels.index.tbl_header_case") }}</v-th>
                        <v-th>{{ $t("travels.index.tbl_header_text") }}</v-th>
                        <v-th class="text-right">{{
                            $t("travels.index.tbl_header_kms")
                        }}</v-th>
                        <v-th class="text-right">{{
                            $t("travels.index.tbl_header_km_money")
                        }}</v-th>
                    </v-tr>
                </v-thead>
                <v-tbody>
                    <v-tr v-for="t in travels" :key="t.id">
                        <v-td>
                            {{ $d(new Date(t.travel_date), "short") }}
                            <div class="small text-muted">
                                {{
                                    $t("rate_#RATE", {
                                        RATE: $n(t.tax_rate_per_km, {
                                            style: "currency",
                                            currency: t.tax_rate_currency,
                                            currencyDisplay: "code",
                                        }),
                                    })
                                }}
                            </div>
                        </v-td>
                        <v-td
                            ><router-link
                                v-if="t.project_id"
                                :to="{
                                    name: 'ProjectViewTransport',
                                    params: {
                                        project_id: t.project.id,
                                    },
                                }"
                                >{{ t.project.title }}</router-link
                            >
                            <div class="small text-muted">
                                <template v-if="t.project_id">
                                    #{{ t.project.id }} </template
                                ><template v-else>---</template>
                            </div>
                        </v-td>
                        <v-td
                            >{{ t.invoice_text }} /
                            {{ t.notes }}
                        </v-td>
                        <v-td class="text-right">{{ t.kms }} km</v-td>
                        <v-td class="text-right">{{
                            $n(t.kms * t.tax_rate_per_km, {
                                style: "currency",
                                currency: t.tax_rate_currency,
                                currencyDisplay: "code",
                            })
                        }}</v-td>
                    </v-tr>
                </v-tbody>
            </v-table>
            <div class="paginator">
                <base-pagination
                    :page-count="meta.pagination.total_pages"
                    :per-page="meta.pagination.per_page"
                    :value="meta.pagination.current_page"
                    @input="$handlePagination($event)"
                />
            </div>
        </template>
    </modal>
</template>


<style>
</style>
